import * as React from "react"
import { ImSpinner8 } from "@react-icons/all-files/im/ImSpinner8"

const Spinner = ({ message, size, sizeMessage }) => {
  return (
    <div className="flex flex-col justify-center" style={{ marginTop: "30px" }}>
      <ImSpinner8
        className="animate-spin w-auto mx-auto"
        size={size ?? 60}
        style={{ color: "#00CEA9" }}
      />
      {message ? <p className={`text-center ${!sizeMessage ? 'text-2xl': 'text-lg'}`}> {message}</p> : null}
    </div>
  )
}

export default Spinner
