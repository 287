import config from "../config"

const apiServiceEndpoint = config.serverUri
const homeContentId = "65301f6e965e9b12688fd047"

const getUserMoso = partnerCode => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/socios/${partnerCode}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        response
          .json()
          .then(data => {
            if (data.status === 204) {
              resolve(data.message)
            }
            resolve(data)
          })
          .catch(error => {
            console.log(error)
          })
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getLegalPages = () => {
  return fetch(apiServiceEndpoint + "/legal")
}
const getHome = () => {
  const url = `${apiServiceEndpoint}/seccions/blocks/${homeContentId}?limit=12`
  console.log("Fetching URL:", url)
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(data => {
              resolve(data)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          reject(new Error("Network response was not ok"))
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getAllSections = () => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/seccions`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getSectionById = sectionId => {
  return new Promise((resolve, reject) => {
    fetch(
      apiServiceEndpoint + "/seccions/blocks/" + sectionId + "?limit=12"
    ).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getSectionsId = () => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/seccions`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          let seccionid = []
          data.forEach(item => seccionid.push(item.id))
          resolve(seccionid)
        })
      }
    })
  })
}

const getPathWorkout = (seccion, workout) => {
  console.log(workout)
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/cards/${seccion}`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          console.log(data)
          data.blocks.forEach(bloque => {
            bloque.cards.forEach(tarjeta => {
              if (tarjeta.entrenamiento?.id === workout) {
                resolve({
                  seccion: data.nombre,
                  tipo: tarjeta.tipoDeContenido,
                })
              }
            })
          })
        })
      }
    })
  })
}

const getBlock = (sectionId, blockId) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/" + "blocks" + "/" + blockId).then(
      response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
      }
    )
  })
}

const getBlockPage = (blockTitle, sectionId, page) => {
  return new Promise((resolve, reject) => {
    fetch(
      apiServiceEndpoint +
        "/" +
        "blocks/cards/" +
        blockTitle +
        "?limit=6&page=" +
        page
    ).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getOneBlock = blockId => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/" + "blocks/cards/" + blockId).then(
      response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
      }
    )
  })
}

const getSuggestions = (uid, sectionId) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${apiServiceEndpoint}/sugerencias/${uid}?sectionId=${sectionId}`
    ).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getAudio = id => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/audios/${id}`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getWorkout = id => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/entrenamientos/" + id).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getPlan = id => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/plans/${id}`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getLives = id => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/lives/${id}`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}

const getSearch = (searchTerm, sectionId) => {
  return new Promise((resolve, reject) => {
    // Construye la URL de la API con el searchTerm y sectionId
    const url = `${apiServiceEndpoint}/seccions/blocks/${sectionId}?searchTerm=${searchTerm}`

    fetch(url)
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(data => {
              // Extraer las cartas de todos los bloques
              const allCards = data.blocks.reduce((cards, block) => {
                if (block.cards) {
                  return cards.concat(block.cards)
                }
                return cards
              }, [])

              resolve(allCards)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          reject("La solicitud no fue exitosa.")
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getMobilePayments = token => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments", {
      headers: {
        "x-sc-signature": `${token}`,
      },
    })
      .then(response => {
        if (response.ok) {
          // console.log("Respuesta ok")
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          console.log("Problema")
          reject("No tienes permisos para ver este contenido")
        }
        // console.log(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const getPaymentInfo = id => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/suscripcions`)
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            data.map(getPayment => {
              if (getPayment.id == id) resolve(getPayment)
            })
          })
          if (response.status === 401) {
            console.log("Problema")
            reject("No tienes permisos para ver este contenido")
          }
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
const getUserDetails = userid => {
  return new Promise((resolve, reject) => {
    fetch(`${apiServiceEndpoint}/users/${userid}`).then(response => {
      if (response.ok) {
        response.json().then(data => {
          resolve(data)
        })
      }
    })
  })
}
const saveNewCard = (token, card) => {
  // console.log(card)
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/methods", {
      method: "PUT",
      body: JSON.stringify(card),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          // console.log("Respuesta ok")
          // console.log(response)
          response.json().then(data => {
            resolve(data)
          })
        }

        // console.log('Error?')
        if (response.status === 401) {
          console.log("Respuesta mal")
          console.log(response)
          response.json().then(error => {
            if (error.message && error.message === "incorrect_number")
              reject("Número de tarjeta incorrecto")

            if (error.message && error.message === "card_declined")
              reject("Tarjeta rechazada intenta otro metódo de pago")

            reject("No tienes permisos para realizar esta acción")
          })
        }

        if (response.status === 402) {
          response.json().then(error => {
            if (error.message && error.message === "incorrect_number")
              reject("Número de tarjeta incorrecto")

            if (error.message && error.message === "card_declined")
              reject("Tarjeta rechazada intenta otro metódo de pago")

            reject("No tienes permisos para realizar esta acción")
          })
        }
        console.log("ninguna de las anteriores")
        console.log(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const applyCode = (token, code) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/apply/", {
      method: "POST",
      body: JSON.stringify({ code }),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        } else {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const applyCodeApplePay = code => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/applyCodeApplePay", {
      method: "POST",
      body: JSON.stringify({ code }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        } else {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteCard = (token, paymentMethod) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/methods/" + paymentMethod.id, {
      method: "DELETE",
      body: JSON.stringify(paymentMethod),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const suscribe = (token, paymentMethod, ref, code) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/suscribe", {
      method: "POST",
      body: JSON.stringify({ cardId: paymentMethod, suscriptionId: ref, code }),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            reject("No tienes permisos para realizar esta acción")
          })
        }
        if (response.status === 400) {
          reject("solicitud incorrecta")
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const paymentIntentRequest = (token, customerId, amount) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/paymentIntent", {
      method: "POST",
      body: JSON.stringify({ customerId: customerId, amount: amount }),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            reject("No tienes permisos para realizar esta acción")
          })
        }
        if (response.status === 400) {
          reject("solicitud incorrecta")
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const suscribeApplePay = (token, paymentMethod, ref, code) => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/suscribeApplePay", {
      method: "POST",
      body: JSON.stringify({ cardId: paymentMethod, suscriptionId: ref, code }),
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            reject("No tienes permisos para realizar esta acción")
          })
        }
        if (response.status === 400) {
          reject("solicitud incorrecta")
          // alert(JSON.stringify(response))
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const unSuscribe = token => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/payments/unsuscribe", {
      method: "POST",
      headers: {
        "x-sc-signature": `${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            reject("No tienes permisos para realizar esta acción")
          })
        }
        if (response.status === 400) {
          reject("solicitud incorrecta")
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const uploadMedia = file => {
  return new Promise((resolve, reject) => {
    const data = new FormData()
    data.append("files", file)
    fetch(apiServiceEndpoint + `/upload`, { method: "POST", body: data })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            if (data[0].url[0] !== "/") {
              resolve(data[0].url)
            } else {
              resolve(apiServiceEndpoint + data[0].url)
            }
          })
        } else {
          reject({ code: response.status })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createUser = user => {
  return new Promise((resolve, reject) => {
    const data = new FormData()
    data.append("tecnologia", "SITIO WEB")
    data.append("email", user.email)
    data.append("username", user.email)
    data.append("password", user.password)
    data.append("firebaseId", user.firebaseId)
    if (user.avatar) data.append("avatar", user.avatar)
    data.append("alias", user.alias)
    data.append("nombre", user.firstName)
    data.append("apellido", user.lastName)
    data.append("suscription", "free")
    fetch(apiServiceEndpoint + "/users", { method: "POST", body: data })
      .then(response => {
        if (response.ok) {
          resolve()
        } else {
          reject(response.status)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createSocioUser = user => {
  return new Promise((resolve, reject) => {
    const data = new FormData()
    data.append("tecnologia", "SITIO WEB")
    data.append("email", user.email)
    data.append("username", user.email)
    data.append("password", user.password)
    data.append("firebaseId", user.firebaseId)
    if (user.avatar) data.append("avatar", user.avatar)
    data.append("alias", user.alias)
    data.append("nombre", user.firstName)
    data.append("apellido", user.lastName)
    data.append("sportCityId", user.sportCityId)
    fetch(apiServiceEndpoint + "/socios", { method: "POST", body: data })
      .then(response => {
        console.log(response)
        if (response.ok) {
          resolve()
        } else {
          reject(response.status)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createAccess = token => {
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/reportb2b-accesos", {
      method: "POST",
      headers: {
        "x-sc-signature": `${token}`,
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            resolve(res)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createContentReproduced = (token, workout) => {
  const data = new FormData()
  data.append("contenidoId", workout.contenidoId)
  data.append("contenido", workout.contenido)
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/reportb2b-cont-rep", {
      method: "POST",
      body: data,
      headers: {
        "x-sc-signature": `${token}`,
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(res => {
            resolve(res)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
        if (response.status === 400) {
          response.json().then(err => reject(err))
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createContentViews = (token, workout) => {
  const data = new FormData()
  data.append("contenidoId", workout.contenidoId)
  data.append("contenido", workout.contenido)
  data.append("tiempo", workout.tiempo)
  return new Promise((resolve, reject) => {
    fetch(apiServiceEndpoint + "/reportb2b-cont-visto", {
      method: "POST",
      body: data,
      headers: {
        "x-sc-signature": `${token}`,
      },
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            resolve(data)
          })
        }
        if (response.status === 401) {
          response.json().then(error => {
            console.error(error)
            reject("No tienes permisos para realizar esta acción")
          })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export {
  getUserMoso,
  getLegalPages,
  getHome,
  getAllSections,
  getBlockPage,
  getOneBlock,
  getSectionById,
  getSectionsId,
  getPathWorkout,
  getSuggestions,
  uploadMedia,
  createUser,
  createSocioUser,
  getBlock,
  getAudio,
  getWorkout,
  getPlan,
  getLives,
  getSearch,
  getMobilePayments,
  getPaymentInfo,
  applyCode,
  applyCodeApplePay,
  saveNewCard,
  deleteCard,
  suscribe,
  unSuscribe,
  getUserDetails,
  paymentIntentRequest,
  suscribeApplePay,
  createAccess,
  createContentReproduced,
  createContentViews,
}
